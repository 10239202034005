.hoverable-card {
  cursor: pointer;
  margin-bottom: 1rem;
  /* Add margin at the bottom */
  transition: background-color 0.3s ease-in-out;
}

.hoverable-card:hover {
  background-color: #f5f5f5;
  /* Light grey background on hover */
}

.mobile-app-widget {
  padding-bottom: 1rem;
  /* Add padding inside the card */
}