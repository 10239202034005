/* General Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 250px;
  background-color: #343a40;
  color: #fff;
  overflow-y: auto;
  transition: transform 0.3s ease;
  z-index: 1040;
}

.sidebar.closed {
  transform: translateX(-250px);
}

.rtl .sidebar.closed {
  transform: translateX(250px);
}

.ltr .sidebar {
  left: 0;
}

.rtl .sidebar {
  right: 0;
}

.sidebar-content {
  /* padding-top: 56px; */
  /* Height of the navbar */
}

.sidebar-overlay {
  display: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-250px);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .sidebar-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1030;
  }

  .content {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

/* Nav Links */
.sidebar .nav-link {
  color: #495057;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;
}

.sidebar .nav-link:hover {
  background-color: #6094a8;
  color: #fff;
}

.sidebar .nav-link.active {
  background-color: #6094a8;
  color: #fff;
}

/* Icons */
.sidebar-icon {
  margin-right: 12px;
  font-size: 18px;
}

.rtl .sidebar-icon {
  margin-left: 12px;
  margin-right: 0;
}

/* Text */
.sidebar-text {
  flex-grow: 1;
}

/* Category Styles */
.sidebar-category {
  border-top: 1px solid #6094a8;
}

.sidebar-category-header {
  background: none;
  border: none;
  color: #495057;
  width: 100%;
  text-align: left;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.sidebar-category-header:hover {
  background-color: #6094a8;
  color: #fff;
}

.sidebar-category-title {
  display: flex;
  align-items: center;
}

/* Chevron Icon */
.sidebar-chevron {
  transition: transform 0.2s ease;
}

.sidebar-chevron.open {
  transform: rotate(90deg);
}

/* Adjust chevron icon rotation for RTL */
.rtl .sidebar-chevron {
  transform: rotate(180deg);
}

.rtl .sidebar-chevron.open {
  transform: rotate(90deg);
}

/* Submenu Links */
.sidebar .nav .nav-link {
  padding-left: 40px;
}

.rtl .sidebar .nav .nav-link {
  padding-left: 20px;
  padding-right: 40px;
}

/* Logout */
.sidebar-logout {
  margin-top: auto;
  border-top: 1px solid #6094a8;
}