.tooltip {
  z-index: 99;

  .tooltip-inner {
    background-color: #adb7c5;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 15px;
  }

  &.bs-tooltip-right .arrow::before,
  &.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #adb7c5;
  }

  &.bs-tooltip-top .arrow::before,
  &.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #adb7c5;
  }

  &.bs-tooltip-bottom .arrow::before,
  &.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #adb7c5;
  }

  &.bs-tooltip-left .arrow::before,
  &.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #adb7c5;
  }
}
