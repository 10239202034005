/* Container for the input */
.input-container {
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    border: 2px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
    margin-bottom: 15px;
  }
  
  /* Input element */
  .input-element {
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
    color: #6094a8;
  }
  
  /* Placeholder styles */
  .input-element::placeholder {
    color: #aaa;
  }
  
  /* Focused state */
  .input-container.focused {
    border-color: #6094a8;
  }
