.content {
  padding: 20px;
  transition: margin 0.3s ease;
}

@media (max-width: 768px) {
  .content {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}