.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #6094a8;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-family: Arial, sans-serif;
  width: 100%;
}

.checkbox-container input[type="checkbox"] {
  appearance: none;
  background-color: white;
  border: 2px solid #ddd;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  margin-bottom: 3px;
}

.checkbox-container input[type="checkbox"]:checked {
  background-color: white;
  border-color: white;
}

.checkbox-container input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 3px;
  left: 8px;
  width: 5px;
  height: 10px;
  border: solid #6094a8;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-container label {
  cursor: pointer;
  margin: 0;
  font-weight: 100;
  padding-left: 10px;
}

.disabled {
  background-color: #b1c3c8;
}

.checkbox-container-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-family: Arial, sans-serif;
  width: 100%;
}


.checkbox-container-title input[type="checkbox"] {
  appearance: none;
  background-color: white;
  border: 2px solid #ddd;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  margin-bottom: 3px;
}

.checkbox-container-title input[type="checkbox"]:checked {
  background-color: white;
  border-color: white;
}

.checkbox-container-title input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 3px;
  left: 8px;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-container-title label {
  cursor: pointer;
  margin: 0;
  padding-left: 10px;
}