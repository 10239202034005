
.custom-dropzone {
    border: 2px dashed #0087F7 !important;
    border-radius: 10px !important;
    padding: 20px !important;
    background-color: #f9f9f9;
  }
  
  .custom-input-label {
    color: #007bff !important;
    font-weight: bold;
  }
  
  .custom-preview {
    border: 1px solid #007bff !important;
    border-radius: 10px !important;
    margin: 10px 0;
  }
  
  .custom-submit-button {
    background-color: #007bff !important;
    color: #fff !important;
    border-radius: 5px !important;
    padding: 10px 20px !important;
    cursor: pointer;
  }
  
