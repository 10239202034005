.topbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.sidebar-toggle {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  /* Adjust to match navbar height */
  border: none;
  background: transparent;
}

.rtl .sidebar-toggle {
  margin-left: 1rem;
  margin-right: 0;
}

.navbar-brand-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

/* Hide the logo on mobile screens */
@media (max-width: 576px) {
  .navbar-brand-logo {
    display: none;
  }
}

.topbar .navbar-nav {
  flex-direction: row;
  margin-left: auto;
}

.rtl .topbar .navbar-nav {
  margin-left: 0;
  margin-right: auto;
}