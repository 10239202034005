.string-array-container {
    /* max-width: 400px;
    margin: auto;*/
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .input-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .string-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 16px;
  }
  
  .add-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #28a745;
    color: white;
    cursor: pointer;
    font-size: 16px;
  }
  
  .add-button:hover {
    background-color: #218838;
  }
  
  .string-list {
    list-style: none;
    padding: 0;
  }
  
  .string-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
  }
  
  .delete-button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #dc3545;
    color: white;
    cursor: pointer;
    font-size: 14px;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
