
.add-property-page--content {
    padding: 10px 20px;

    .property-requests-table {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-radius: 10px;
        transition: box-shadow 0.3s;
        cursor: pointer;

        .inner-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .property-requests-table th,
    .property-requests-table td {
        background-color: transparent;
        border: none;
        font-size: 14px;
        vertical-align: top;
    }

    .property-requests-table td:nth-child(1),
    .property-requests-table th:nth-child(1) {
        p {
            max-width: 200px; 
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    table {
        max-width: 900px;
    }

    .property-requests-table:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        transform: translateY(-1px);
    }
      
    a {
        text-decoration: none;
    }
}

@media (min-width: 1600px) {
    .add-property-page--content {
        .property-requests-table {
            justify-content: space-between;

            .inner-container{
                width: 1200px;
                justify-content: space-between;
            }
        }
        .property-requests-table th,
        .property-requests-table td {
            font-size: 16px;
        }
       .actions {
            margin-left: 30px;
            button {
                font-size: 16px;
            }
       }
    }
}
